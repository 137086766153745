import { FC, useEffect, useState } from 'react';
import cn from 'classnames';
import { useRouter } from 'next/router';

import { TRouter } from '@local-types/global';
import AudioPlayer from '@components/AudioPlayer';
import styles from './Headline.module.scss';

type HeadlineProps = {
  headline: string;
  linkedInUrl?: string;
  xUrl?: string;
  darkTheme?: boolean;
  russianView?: boolean;
};
const Headline: FC<HeadlineProps> = ({
  headline,
  linkedInUrl,
  xUrl,
  darkTheme,
  russianView,
}) => {
  const router = useRouter();

  const socialMediaLiks = [
    {
      alt: 'linktree',
      href: 'https://linktr.ee/1_',
      imgLink: '/assets/logos/linktree.svg',
      width: 105,
      height: 32,
      id: 4,
    },
    {
      alt: 'telegram',
      href: 'https://t.me/smiling',
      imgLink: '/assets/logos/tg.svg',
      width: 30,
      height: 30,
      id: 4,
    },
    {
      alt: 'instagram',
      href: 'https://www.instagram.com/talmud',
      imgLink: '/assets/logos/instagram.svg',
      width: 30,
      height: 30,
      id: 4,
    },
    {
      alt: 'x',
      href: 'https://x.com/AlexanyanWolf',
      imgLink: '/assets/logos/x.svg',
      width: 30,
      height: 30,
      id: 4,
    },
    {
      alt: 'linkedin',
      href: 'https://www.linkedin.com/in/alexanyan/',
      imgLink: '/assets/logos/linkedin.svg',
      width: 30,
      height: 30,
      id: 4,
    },
  ];
  const [title, setTitle] = useState('');
  const [highlightedText, setHighlightedText] = useState('');
  const [secondDescription, setSecondDescription] = useState('');
  const [lastDescription, setLastDescription] = useState('');
  const [fadeOutIndexes, setFadeOutIndexes] = useState([]);
  const [serenityModeStatus, setSereintyModeStatus] = useState(false);
  const [defaultState, setDefaultState] = useState(1);
  const [fadeInIndexes, setFadeInIndexes] = useState([]);

  const { locale } = router as TRouter;
  const serenityText = locale === 'en' ? 'Serenity mode' : 'Покой';
  const exitSerenityText =
    locale === 'en' ? 'Exit serenity' : 'Покинуть режим покоя';

  const handleFadeOut = () => {
    setFadeInIndexes([]);
    setDefaultState(2);

    setSereintyModeStatus(true);
    [0, 1, 2, 3, 4].forEach(index => {
      setTimeout(() => {
        setFadeOutIndexes(prev => [...prev, index]);
      }, index * 350);
    });
  };

  const handleFadeIn = () => {
    setFadeOutIndexes([]);
    setDefaultState(3);

    setSereintyModeStatus(false);
    [0, 1, 2, 3, 4].forEach(index => {
      setTimeout(() => {
        setFadeInIndexes(prev => [...prev, index]);
      }, index * 350);
    });
  };

  useEffect(() => {
    const container = document.createElement('div');
    container.innerHTML = headline;
    const h1 = container.querySelector('h1');
    setTitle(h1.textContent);
    if (locale === 'en') {
      const spans = Array.from(container.querySelectorAll('p > span, p > a'));

      const firstDescription = spans
        .slice(0, 2)
        .map(el => el.outerHTML)
        .join('');
      setHighlightedText(firstDescription);

      const secondDesc = spans
        .slice(2, 3)
        .map(el => el.outerHTML)
        .join('');
      setSecondDescription(secondDesc);

      const lastPart = spans
        .slice(3)
        .map(el => el.outerHTML)
        .join('');
      setLastDescription(lastPart);
    } else if (locale === 'ru') {
      const paragraphs = Array.from(container.querySelectorAll('p'));

      const firstDescription = paragraphs[0]?.innerHTML || '';
      setHighlightedText(firstDescription);

      const secondDesc = paragraphs[1]?.innerHTML || '';
      setSecondDescription(secondDesc);

      const lastPart = paragraphs[2]?.innerHTML || '';
      setLastDescription(lastPart);
    }
  }, [headline, locale]);

  return (
    <section
      className={cn(styles.headline, {
        [styles.darkTheme]: darkTheme,
        [styles.russianView]: russianView,
      })}
    >
      <div className={styles.headlineInfo}>
        <div
          onClick={!serenityModeStatus ? handleFadeOut : null}
          className={styles.test}
        >
          {serenityModeStatus ? (
            <AudioPlayer
              loop
              isSerenity
              className={styles.leaf}
              audioSrc={'/assets/serenity.mp3'}
              playIcon={'/assets/leaf.svg'}
              pauseIcon={'/assets/leaf.svg'}
              startSerenityMode={serenityModeStatus}
            />
          ) : (
            <img
              src={'/assets/leaf.svg'}
              width={12}
              height={18}
              className={styles.leaf}
            />
          )}
          <span
            className={styles.serenityBtn}
            onClick={serenityModeStatus ? handleFadeIn : null}
          >
            {serenityModeStatus ? exitSerenityText : serenityText}{' '}
          </span>
        </div>
        <div className={styles.videoContainer}>
          <video
            controls={false}
            playsInline
            autoPlay
            muted
            loop
            className={styles.video}
            height={600}
          >
            <source src="/assets/CoverVideo.mp4" type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        </div>
        <div className={cn(styles.contentWrapper, {})}>
          <div className={styles.headlineContent}>
            <h1
              className={cn(styles.section, {
                [styles.defaultState]: defaultState === 3,
                [styles.fadeOut]:
                  fadeOutIndexes.includes(0) && !fadeInIndexes.includes(0),
                [styles.fadeIn]:
                  fadeInIndexes.includes(0) && !fadeOutIndexes.includes(0),
              })}
            >
              {title}
            </h1>
            <p
              className={cn(styles.section, {
                [styles.defaultState]: defaultState === 3,
                [styles.fadeOut]: fadeOutIndexes.includes(1),
                [styles.fadeIn]: fadeInIndexes.includes(1),
              })}
              dangerouslySetInnerHTML={{ __html: highlightedText }}
            ></p>
            <p
              className={cn(styles.section, {
                [styles.defaultState]: defaultState === 3,
                [styles.fadeOut]: fadeOutIndexes.includes(2),
                [styles.fadeIn]:
                  fadeInIndexes.includes(2) && !fadeOutIndexes.includes(2),
              })}
              dangerouslySetInnerHTML={{ __html: secondDescription }}
            ></p>
            <p
              className={cn(styles.section, {
                [styles.defaultState]: defaultState === 3,
                [styles.fadeOut]:
                  fadeOutIndexes.includes(3) && !fadeInIndexes.includes(3),
                [styles.fadeIn]: fadeInIndexes.includes(3),
              })}
              dangerouslySetInnerHTML={{ __html: lastDescription }}
            ></p>
          </div>
          <div className={cn(styles.socialMedia, {})}>
            {socialMediaLiks.map(link => (
              <a
                key={link.alt}
                href={link.href}
                target="_blank"
                rel="noreferrer"
                className={cn(styles.section, {
                  [styles.defaultState]: defaultState === 3,
                  [styles.fadeOut]:
                    fadeOutIndexes.includes(link.id) &&
                    !fadeInIndexes.includes(link.id),
                  [styles.fadeIn]: fadeInIndexes.includes(link.id),
                })}
              >
                <img
                  src={link.imgLink}
                  alt={link.alt}
                  width={link.width}
                  height={link.height}
                />
              </a>
            ))}
          </div>
        </div>
        <div className={styles.videoContainerMobile}>
          <video
            controls={false}
            playsInline
            autoPlay
            muted
            loop
            className={styles.video}
            height={600}
          >
            <source src="/assets/CoverVideo.mp4" type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        </div>
      </div>
    </section>
  );
};

export default Headline;
